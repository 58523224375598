<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("educationProgram.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="newItem" v-if="selectedId !== ''">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            
            <div class="row">
              <div class="col-lg-3">
                <table class="table table-bordered">
                  <thead class="thead-light">
                  <tr>
                    <th>Параллель</th>
                    <th>Язык обучения</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="program in programs" :key="program.id" @click="select(program)"
                    :class="{'selected':selectedId == program.id}">
                    <td>{{program.level}}</td>
                    <td>{{program.language}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-lg-9" style="border-left: 4px solid black !important;">
                <table class="table table-bordered">
                  <thead class="thead-light">
                  <tr>
                    <th class="text-left">
                      {{ $t("groupSubjects.list.subject") }}
                    </th>
                    <th class="text-left">
                      Преподаватель
                    </th>
                    <th class="text-left">
                      {{ $t("groupSubjects.list.name") }}
                    </th>
                    <th class="text-left">
                      {{ $t("groupSubjects.list.classWorkPerWeekHours") }}
                    </th>
                    <th class="text-left">
                      {{ $t("groupSubjects.list.roomNumber") }}
                    </th>    
                    <th class="text-left">
                      Дополнительный компонент
                    </th>                
                    <th class="text-left">
                      Четвертные не ставятся
                    </th>    
                    <th class="text-left">
                     Делиться на подгруппы
                    </th>      
                    <th class="text-left">
                      Количество учеников необходимое для деления в подгруппы
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="programSubject in programSubjects" :key="programSubject.id">
                    <td>{{programSubject.subjectName}}</td>
                    <td>{{programSubject.teacherName}}</td>
                    <td>{{programSubject.name}}</td>
                    <td>{{programSubject.classWorkPerWeekHours}}</td>
                    <td>{{programSubject.roomNumber}}</td>
                    <td>{{showBoolean(programSubject.isAdditional)}}</td>
                    <td>{{showBoolean(programSubject.allowNoQuarterMarks)}}</td>
                    <td>{{showBoolean(programSubject.isDivideIntoSubgroups)}}</td>
                    <td>{{programSubject.divideIntoSubgroupsStudentsCount}}</td>
                    <td>
                      <button class="btn btn-icon btn-success" style="margin-right: 10px;" @click="editSubject(programSubject)">
                        <i class="fa fa-pen"></i>
                      </button>         
                      <button class="btn btn-icon btn-danger" @click="deleteSubject(programSubject)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b-modal :no-close-on-backdrop="true" ref="schoolProgramSubject-modal">
              <template v-slot:modal-title>
                {{ $t("common.new") }}
              </template>
              <b-form>
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("groupSubjects.list.subject") }}
                  </template>
                  <b-form-select
                      id="input-2"
                      v-model="form.subjectId"
                      :options="subjectDictionary"
                      :state="modelState.subjectId"
                      required
                  ></b-form-select>
                  <div class="invalid-feedback">{{ modelSaveErrors.subjectId }}</div>
                </b-form-group>
                <b-form-group>
                  <template v-slot:label>
                    Преподаватель
                  </template>
                  <b-form-select
                      id="input-2"
                      v-model="form.teacherId"
                      :options="teachers"
                      :state="modelState.teacherId"
                      required
                  ></b-form-select>
                  <div class="invalid-feedback">{{ modelSaveErrors.teacherId }}</div>
                </b-form-group>
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("groupSubjects.list.name") }}
                  </template>
                  <b-form-input
                      v-model="form.name"
                      :state="modelState.name"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ modelSaveErrors.name }}</div>
                </b-form-group>
                <b-form-group id="input-group-2" label-for="input-2">
                  <template v-slot:label>
                    {{ $t("groupSubjects.list.classWorkPerWeekHours") }}
                  </template>
                  <b-form-select
                      v-model="form.classWorkPerWeekHours"
                      :options="classWorkPerWeekHoursList"
                      :state="modelState.classWorkPerWeekHours"
                  ></b-form-select>
                  <div class="invalid-feedback">{{ modelSaveErrors.classWorkPerWeekHours }}</div>
                </b-form-group>
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("groupSubjects.list.roomNumber") }}
                  </template>
<!--                  <b-form-input
                      v-model="form.roomNumber"
                      :state="modelState.roomNumber"
                  ></b-form-input>-->
                  <b-form-select
                      v-model="form.classroomId"
                      :options="classrooms"
                      :state="modelState.classroomId"
                  ></b-form-select>
                  <div class="invalid-feedback">{{ modelSaveErrors.classroomId }}</div>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="form.isAdditional">
                    Дополнительный урок (школьный или иной компонент)
                  </b-form-checkbox>
                </b-form-group>         
                <b-form-group>
                  <b-form-checkbox v-model="form.allowNoQuarterMarks">
                    Четвертные оценки не ставятся
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="form.isDivideIntoSubgroups">
                    Делиться на подгруппы
                  </b-form-checkbox>
                </b-form-group>      
                <b-form-group>
                  <template v-slot:label>
                    Количество учеников необходимое для деления в подгруппы
                  </template>
                  <b-form-input v-model="form.divideIntoSubgroupsStudentsCount">                    
                  </b-form-input>
                </b-form-group>
              </b-form>

              <template v-slot:modal-footer>
                <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
                  {{ $t("common.save") }}
                </b-button>
                <b-button type="reset" @click="onReset" variant="danger">
                  {{ $t("common.cancel") }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.selected td {
  background-color: #acacac;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
export default {
  name: "SchoolProgram",
  components: {
    KTPortlet
  },
  data() {
    return {
      languages:[{text: "Кыргызский", value: 1}, {text: "Русский", value: 2}],
      languageValues:{
        1: "Кыргызский",
        2: "Русский",
      },
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      programs:[],
      programSubjects:[],
      selectedId:'',
      selectedProgram:'',
      subjectDictionary: null,
      classWorkPerWeekHoursList: [],
      form:{
        name: "",
        subjectId: "",
        classWorkPerWeekHours: "",
        roomNumber: "",
        isDivideIntoSubgroups: false,
        allowNoQuarterMarks: false,
        divideIntoSubgroupsStudentsCount: "",
        classroomId: 0,
      },
      modelState:{},
      modelSaveErrors:{},
      teachers: [],
      classrooms: [],
    };
  },
  mounted() {
    this.classWorkPerWeekHoursList = DictionariesService.getClassWorkPerWeekHoursList();
    
    let $this = this;
    ApiService.querySecured("dictionaries/subjects", {
      params: {groupId: this.$route.params.id}
    }).then(({data}) => {
      $this.subjectDictionary = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/teachersLoggedUserSchool", {}).then(({data}) => {
      $this.teachers = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/getClassrooms", {}).then(({data}) => {
      $this.classrooms = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.createPrograms();
  },
  methods: {
    createPrograms(){
      for (let i in this.levels){
        let level = this.levels[i];
        for (let langKey in this.languageValues){
          let lang = this.languageValues[langKey];
          this.programs.push({
            id:level + "-" + langKey,
            level: level,
            languageId: langKey,
            language: lang
          })
        }
      }
    },
    select(item){
      this.selectedProgram = item;
      this.selectedId = item.id;
      
      this.load();
    },
    newItem(){
      this.form = {};
      this.$refs['schoolProgramSubject-modal'].show();
    },
    editSubject(subject){
      this.form = subject;
      this.$refs['schoolProgramSubject-modal'].show();
    },
    load(){
      ApiService.querySecured("schoolProgram/list", {
        params: {level: this.selectedProgram.level, language:this.selectedProgram.languageId}
      }).then(({data}) => {
        this.programSubjects = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    onSubmit(evt){
      
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;
      
      this.form.level = this.selectedProgram.level;
      this.form.LanguageId = this.selectedProgram.languageId;

      ApiService.postSecured("schoolProgram/save", this.form)
          .then(function () {
            $this.$refs["schoolProgramSubject-modal"].hide();
            $this.load();
            $this.form = {};
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }
          }).finally(function () {
        submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
      });


    },
    deleteSubject(subject){
      let $this = this;
      if(confirm("Удалить предмет?")){
        ApiService.postSecured("schoolProgram/delete", {id:subject.id})
            .then(function () {

              $this.load();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    },
    onReset(){
      this.form = {};

      this.$refs["schoolProgramSubject-modal"].hide();
    },
    showBoolean(value){
      if(value === true){
        return "Да";
      }
      
      return '';
    }
  }
};
</script>
